<template>
	<a-modal width="50%" :visible="visible" :title="title" :closable="false">
		<template slot="footer">
			<a-button key="back" @click="onCancel"> {{ $t('general.close') }} </a-button>
			<a-button key="submit" class="btn btn-success" @click="handleSubmit"> {{ $t('general.save') }} </a-button>
		</template>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<a-form layout="vertical" :form="form" @submit="handleSubmit">
				<div class="row">
					<div class="col-md-4">
						<a-form-item :label="$t('catalogsModule.taxRegimes.tableHeaders.satKey')">
							<a-input
								v-decorator="[
									'code',
									{
										rules: requiredFileds,
									},
								]"
								class="text-uppercase"
								autocomplete="off"
								disabled
							/>
						</a-form-item>
					</div>
					<div class="col-md-8">
						<a-form-item :label="$t('catalogsModule.taxRegimes.tableHeaders.name')">
							<a-input
								v-decorator="[
									'description',
									{
										rules: requiredFileds,
									},
								]"
								autocomplete="off"
								disabled
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item :label="$t('catalogsModule.taxRegimes.tableHeaders.vat')">
							<a-input v-decorator="['iva']" autocomplete="off" v-mask="'##'" suffix="%" />
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item :label="$t('catalogsModule.taxRegimes.tableHeaders.isr')">
							<a-input v-decorator="['isr']" autocomplete="off" v-mask="'##'" suffix="%" />
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item :label="$t('catalogsModule.taxRegimes.tableHeaders.vatWithholding')">
							<a-input v-decorator="['iva_retention']" autocomplete="off" v-mask="'##'" suffix="%" />
						</a-form-item>
					</div>
				</div>
			</a-form>
		</a-spin>
	</a-modal>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'

export default {
	name: 'taxRegimesDetailComponent',
	directives: {
		mask,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('taxRegimes', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		onCancel() {
			this.$emit('close')
			this.form.resetFields()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.actualRecord, 'id', false)) {
						payload.id = this.actualRecord.id
						await this.$store.dispatch('taxRegimes/UPDATE', payload)
					} else {
						await this.$store.dispatch('taxRegimes/CREATE', payload)
					}
					this.onCancel()
				}
			})
		},
		setLocalValues() {
			this.form.setFieldsValue({
				code: utilities.objectValidate(this.actualRecord, 'code', ''),
				description: utilities.objectValidate(this.actualRecord, 'description', ''),
				iva: utilities.objectValidate(this.actualRecord, 'iva', 0),
				isr: utilities.objectValidate(this.actualRecord, 'isr', 0),
				iva_retention: utilities.objectValidate(this.actualRecord, 'iva_retention', 0),
			})
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'id', false) && this.visible) {
					setTimeout(() => {
						this.setLocalValues()
					}, 100)
				}
			},
		},
		visible(isVisible) {
			if (isVisible && utilities.objectValidate(this.actualRecord, 'id', false)) {
				setTimeout(() => {
					this.setLocalValues()
				}, 100)
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>