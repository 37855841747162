<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<!-- <div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">{{ $t('general.add') }}</a-button>
					<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
				</div>
			</div> -->
			<div class="row pt10">
				<div class="col-md-12 pt10">
					<a-table :columns="columns" :dataSource="taxRegimesList">
						<div slot="description" slot-scope="record">
							<div class="text-left">
								{{ record.description }}
							</div>
						</div>
						<div slot="iva" slot-scope="record">{{ record.iva }}%</div>
						<div slot="isr" slot-scope="record">{{ record.isr }}%</div>
						<div slot="iva_retention" slot-scope="record">{{ record.iva_retention }}%</div>
						<div slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
							<!-- <a-button class="ml5" size="small" icon="minus-circle" @click="onToggleRecord(record.id)" /> -->
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<taxRegimesDetailComponent :visible="modal.visible" :title="modal.title" @close="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import taxRegimesDetailComponent from '@/components/taxRegimes/detail'

export default {
	name: 'taxRegimesListComponent',
	components: {
		taxRegimesDetailComponent,
	},
	computed: {
		...mapGetters('taxRegimes', ['spinnerLoader', 'spinnerLoaderLabel', 'taxRegimesList']),
		columns() {
			let columns = [
				{
					title: this.$t('catalogsModule.taxRegimes.tableHeaders.satKey'),
					dataIndex: 'code',
					align: 'center',
					width: '10%',
				},
				{
					title: this.$t('catalogsModule.taxRegimes.tableHeaders.name'),
					scopedSlots: { customRender: 'description' },
					align: 'center',
				},
				// {
				// 	title: this.$t('catalogsModule.taxRegimes.tableHeaders.vat'),
				// 	scopedSlots: { customRender: 'iva' },
				// 	align: 'center',
				// 	width: '10%',
				// },
				// {
				// 	title: this.$t('catalogsModule.taxRegimes.tableHeaders.isr'),
				// 	scopedSlots: { customRender: 'isr' },
				// 	align: 'center',
				// 	width: '10%',
				// },
				// {
				// 	title: this.$t('catalogsModule.taxRegimes.tableHeaders.vatWithholding'),
				// 	scopedSlots: { customRender: 'iva_retention' },
				// 	align: 'center',
				// 	width: '10%',
				// },
				// {
				// 	title: this.$t('general.actions'),
				// 	scopedSlots: { customRender: 'action' },
				// 	align: 'right',
				// },
			]
			return columns
		},
	},
	data() {
		return {
			searchText: '',
			bankingInstitutionsListFiltered: [],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
		}
	},
	mounted() {
		if (this.taxRegimesList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('taxRegimes/GET')
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: this.$t('catalogsModule.taxRegimes.add.title'),
			}
		},
		onEditRecord(id) {
			this.$store.dispatch('taxRegimes/GET_ONE', id)
			this.modal = {
				visible: true,
				title: this.$t('catalogsModule.taxRegimes.edit.title'),
			}
		},
		onCloseModal() {
			this.modal = {
				visible: false,
			}
			this.$store.commit('taxRegimes/SET_STATE', {
				actualRecord: {},
			})
		},
		onToggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('taxRegimes/TOGGLE', id)
				}
			})
		},
	},
}
</script>