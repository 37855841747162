<template>
	<div class="card">
		<div class="card-body">
			<taxRegimesListComponent />
		</div>
	</div>
</template>

<script>
import taxRegimesListComponent from '@/components/taxRegimes/list'

export default {
	name: 'bankingInstitutionsView',
	components: {
		taxRegimesListComponent,
	},
}
</script>